<template>
  <v-container fluid>
    <div class="d-flex mb-6 align-center" style="gap: 20px">
      <v-spacer />
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        class="mx-auto"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <h1 :class="`primary--text text-center mx-auto py-2 my-2 ${$vuetify.theme.dark ? 'pickerDark' : 'picker'}`" v-bind="attrs" v-on="on" style="width: 300px;font-size: xx-large;border-radius: 5px;">
            {{ date ? new Date(date).toLocaleDateString('esp',{ weekday :'short', day:'numeric',month:'short', year:'numeric' }).toLocaleUpperCase() : "CARGANDO..." }}
          </h1>
        </template>
        <v-date-picker
          v-model="dateAux"
          :first-day-of-week="1"
          no-title
          scrollable
          event-color="error"
          @click:date="menu = false;"
        ></v-date-picker>
      </v-menu>
      <v-spacer />
      <v-btn outlined icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents && isNotPb && isCat && (!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['NECESIDADES']))" :to="`/necesidades/${ date }`" >
        <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-glass-wine</v-icon>
      </v-btn>
      <v-btn outlined icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents && isNotPb && isCat && (!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['NECESIDADES']))" :to="`/check_catering/${ date }`" >
        <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-truck</v-icon>
      </v-btn>
      <v-btn outlined :color="$vuetify.theme.dark ? '#53ADD4' : '#2491BF'" icon v-if="hasEvents && isNotPb && (!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['CONTROL_HORAS']))" :to="`/horas/${ date }`" >
        <v-icon small style="text-shadow: .6px .6px rgba(0, 0, 0, .7);">mdi-human-male</v-icon>
      </v-btn>
      <v-btn outlined :color="$vuetify.theme.dark ? '#53ADD4' : '#2491BF'" icon v-if="hasEvents && isNotPb && calc && (!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD']) || !!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['EVENTOS']))" :to="`/costes/${ date }`" >
        <v-icon small style="text-shadow: .6px .6px rgba(0, 0, 0, .7);">mdi-alarm-panel</v-icon>
      </v-btn>
      <v-btn outlined :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" icon v-if="(!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['PERSONAS']))" :to="`/equipo/editar`" >
        <v-icon small style="text-shadow: .6px .6px rgba(0, 0, 0, .7);">mdi-calendar</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn @click.stop="savePlan" :loading="saving" color="primary">
        <v-icon left>mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </div>
    <div class="d-flex mb-5" style="gap: 20px">
      <v-card min-width="250">
        <v-card-title>TOTAL PERSONAS</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <h2>{{ totalComensales }}</h2>
        </v-card-text>
      </v-card>
      <v-card min-width="250">
        <v-card-title>TOTAL EVENTOS</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <h2>{{ totalEventos }}</h2>
        </v-card-text>
      </v-card>
      <v-card min-width="250">
        <v-card-title>EXTRAS NECESARIO</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <h2>{{ totalNecesario }}</h2>
        </v-card-text>
      </v-card>
      <v-card min-width="250">
        <v-card-title>CONFIRMADOS</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <h2>{{ totalAsignados }}</h2>
        </v-card-text>
      </v-card>
      <v-card min-width="250">
        <v-card-title>PENDIENTES (faltan)</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <h2>{{ totalNecesario - totalAsignados }}</h2>
        </v-card-text>
      </v-card>
    </div>
    <div class="d-flex flex-wrap mb-6" style="gap: 20px">
      <PlanificacionHueco
        :cuentan="categorias"
        v-for="(h, i) in huecos"
        :key="i"
        :hueco="h"
        v-model="huecos[i]"
        :all-members="confirmedEquipo"
        :date="dateParsed"
      />
    </div>
    <v-row>
      <v-col cols="12" md="5" offset-md="1">
        <tarjeta-datos header>

					<template v-slot:titulo>
						<span>Equipo</span>
						<v-spacer></v-spacer>
						<v-btn to="/equipo/editar" small rounded color="primary">
							<v-icon left>mdi-pencil</v-icon>
							Editar
						</v-btn>
					</template>

          <v-text-field
            v-model="searchEquipo"
            prepend-inner-icon="mdi-magnify"
            hide-details
            dense
            solo
          >
          <template v-slot:append-item>
            <v-list-item>
              <v-list-item-content>
                <span v-for="e in empleados.filter(em => em.nombre.toLocaleLowerCase().indexOf(searchEquipo.toLocaleLowerCase) > -1)">{{ e.nombre }}</span>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-text-field>
          <!-- <div class="d-flex ma-2" style="gap: 5px"> -->
          <div class="d-flex ma-2 flex-wrap" style="gap: 5px">
            <v-chip
              v-for="cat in Object.values(filterCategories)"
              :key="cat.name"
              class="v-chip--active font-weight-black"
              :color=" cat.value ? cat.color : ''"
              outlined
              @click.stop="toggleCatFilter(cat)"
              >{{ cat.name }}</v-chip
            >
          </div>

          <v-list dense>
            <v-list-item-group>
              <UnidadEquipo
                :showRepo="false"
                style="z-index:2"
                :estados="estadosUnidadesEquipo"
                v-for="unidad in filteredEquipo"
                :key="unidad.idUnidadEquipo"
								:value="unidad"
              />
                <!-- v-model="equipo[index]" -->
            </v-list-item-group>
          </v-list>
        </tarjeta-datos>
      </v-col>
      <v-col cols="12" md="5">
        <tarjeta-datos header titulo="Confirmados">
          <v-text-field
            v-model="searchEquipoConfirmados"
            prepend-inner-icon="mdi-magnify"
            hide-details
            dense
            solo
          ></v-text-field>
          <v-list dense>
            <UnidadEquipo
              :showRepo="true"
              style="z-index:1"
              :estados="estadosUnidadesEquipo"
              v-for="(unidad, i) in filteredConfirmedEquipo"
              :disabled="unidad.used"
              :key="unidad.idUnidadEquipo"
              :value="unidad"
              drag
              @changeUsed="toggleUsed(unidad)"
            />
          </v-list>
        </tarjeta-datos>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
export default {
  props: {
    date: String,
  },
  components: {
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    PlanificacionHueco: () => import("../components/PlanificacionHueco.vue"),
    UnidadEquipo: () => import("../components/UnidadEquipo.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      refreshKey: 0,
      ordenTipos: ["Boda", "Comunión", "Corporativo", "Evento", "Pruebas boda"],
      ordenEspacios: ["PISCIS", "MASMONZON", "LALANNE", "LAUS", "CATERING"],
      ordenSalones: ["ROSA", "ROYAL", "CONTINENTAL", "EL JARDIN", "LA CUCHARILLA", "MULTIUSOS", "PISTAS", "CLASH", "Blanco", "Antiguo", "LAGO", "LOS VIÑEDOS"],
      ordenHorarios: ["MAÑANA", "MAÑANA Y COMIDA", "TODO EL DÍA", "COMER", "COMIDA Y CENA", "TARDE", "TARDE Y CENA", "CENA", "PENDIENTE"],
      huecos: [],
      equipo: [],
      categorias: [],
      cuentan: [],
      saving: false,
      confirmados: [],
      loading: false,
      estadosUnidadesEquipo: [],
      searchEquipo: null,
      searchEquipoConfirmados: null,
      filterCategories: {},
      menu: false,
			autosave: null,
      dateAux: new Date(this.date + ' 12:00:00').toISOString().split('T')[0]
    };
  },
  watch:{
    dateAux:{
      async handler(v) {
        const confirmation = await this.$refs.confirm.open(
          "Guardar los cambios",
          "¿Quieres guardar los cambios antes de irte?",
          { color: "primary" }
        );
        if (confirmation) {
          await this.savePlan(true);
        }
        this.$set(this.$data, "dateAux", this.dateAux)
        this.$router.push({path : "/planificacion/" + this.dateAux}).catch(() => {})
      },
    }
  },
  computed: {
    // categorias() {
    //   return this.equipo
    //     .map((x) => ({ name: x.nombreCategoria, color: x.color }))
    //     .filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
    // },
    filteredEquipo() {
			const items = this.equipo.filter((u) => this.filterCategories[ u.nombreCategoria ].value );
      if (!this.searchEquipo) return items;
      return items.filter((u) => {
        const text = this.searchEquipo.toLowerCase();
        return u.nombre_completo.toLowerCase().includes(text);
      });
    },
    filteredConfirmedEquipo() {
      if (!this.searchEquipoConfirmados) return this.confirmedEquipo;
      return this.confirmedEquipo.filter((u) => {
        const text = this.searchEquipoConfirmados.toLowerCase();
        return u.nombre_completo.toLowerCase().includes(text);
      });
    },
    confirmedEquipo() {
      return this.equipo
        .filter(
          (u) =>
            u.EstadoUnidadesEquipo &&
            u.EstadoUnidadesEquipo.idEstadoUnidadesEquipo == 3
        )
        .map((u) => {
          u.used = false;
          this.refreshKey;
          if(u.reposicionar == null) u.reposicionar = false;
          for (const { plan } of this.huecos) {
            u.used = (plan.find((_u) => _u.idUnidadEquipo == u.idUnidadEquipo) && u.reposicionar == false)
              ? true
              : false;
            if (u.used) break;
          }
          return u;
        })
        .sort((a, b) => {
          return a.used - b.used;
        });
    },
    totalComensales() {
      return this.huecos.reduce((a, c) => a + c.nAdultos, 0);
    },
    totalEventos() {
      return this.huecos.reduce((a, c) => a + c.nEventos, 0);
    },
    dateParsed(){
      return new Date(this.date).toLocaleDateString('esp')
    },
    totalFaltan() {
      return this.huecos.reduce((a, c) => a + c.faltan, 0);
    },
    totalAsignados() {
			return this.equipo.filter( (e) => e?.EstadoUnidadesEquipo?.idEstadoUnidadesEquipo == 3 && this.cuentan.includes(e.idCategoriaEquipo) ).length
			// return this.equipo.filter( (e) => e?.EstadoUnidadesEquipo?.idEstadoUnidadesEquipo == 3 && (this.cuentan.includes(e.idCategoriaEquipo) || e.num > 0) ).length
    },
		totalNecesario() {
      return this.huecos.reduce((a, c) => a + Math.ceil(c.nAdultos / c.dist), 0);
			// return this.totalFaltan
		},
  },
  methods: {
    toggleUsed(i){
      i.reposicionar = true;
      this.refreshKey++;
    },
    toggleCatFilter({ name }) {
      this.$set(
        this.filterCategories,
        name,
        { ...this.filterCategories[name], value : !this.filterCategories[name].value }
      );
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("miembro", JSON.stringify(item));
    },
    toggleConfirm(id) {
      if (this.confirmados.includes(id)) {
        const index = this.confirmados.indexOf(id);
        if (index !== -1) {
          this.confirmados.splice(index, 1);
        }
      } else {
        this.confirmados.push(id);
      }
    },
    async getCategorias(){
      const { data } = await axios({
        url: `/opciones/categorias`,
      });
      this.categorias = data
      this.categorias.forEach(c => {
        if(c.cuenta){
          this.cuentan.push(c.idCategoriaEquipo)
        }
      });
    },
    async getHuecos() {
      const { data } = await axios({
        url: `/planificacion/${this.date}`,
      });

      this.huecos = data;
      this.huecos.sort((a, b) => 
      {
        var keyA = this.ordenTipos.indexOf(a.tipo);
        var keyB = this.ordenTipos.indexOf(b.tipo);
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        keyA = this.ordenHorarios.indexOf(a.horario);
        keyB = this.ordenHorarios.indexOf(b.horario);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;

        keyA = this.ordenEspacios.indexOf(a.lugar.split('-')[0].slice(0,-1));
        keyB = this.ordenEspacios.indexOf(b.lugar.split('-')[0].slice(0,-1));
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        keyA = this.ordenSalones.indexOf(a.lugar.split('-')[1].slice(1));
        keyB = this.ordenSalones.indexOf(b.lugar.split('-')[1].slice(1));
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        return 0;
      });
    },
    async getEstadosUnidades() {
      const { data } = await axios({
        url: `/estado_unidades_equipo`,
      });

      this.estadosUnidadesEquipo = data;
    },
    async getConfirmados() {
      const { data } = await axios({
        url: `/planificacion/${this.date}/confirmados`,
      });
      this.$set(this.$data, "confirmados", data);
    },
    async getEquipo() {
      this.loading = true;
      const { data } = await axios({
        url: "/unidades_equipo",
        params: {
          fecha: this.date,
        },
      });
      this.$set(this.$data, "equipo", data);

      this.filterCategories = this.equipo
        .map((x) => ({ name: x.nombreCategoria, color: x.color, value : true }))
				.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i)
				.reduce( (t, c) => {
					t[ c.name ] = c
					return t
				}, {} )

      this.loading = false;
    },
    async savePlan(skip) {
      console.log(this.date + " " + new Date().getTime());
      const confirmation =
        skip ||
        (await this.$refs.confirm.open(
          "Guardar los cambios",
          "¿Estás seguro de que quieres guardar esta planificación?",
          { color: "primary" }
        ));
      if (!confirmation) return;

      const result = this.huecos.reduce((r, c) => {
        console.log(c.custom_key + " plan: ", c.plan);
        if (c.plan && c.plan.length > 0)
          r[c.custom_key] ??= c.plan.map(({ idUnidadEquipo, num, conf, car, specialSchedule, obs }) => ({
            idUnidadEquipo,
            num: Number(num),
            conf: Boolean(conf),
            car: Boolean(car),
            obs: String(obs),
            specialSchedule: Number(specialSchedule)
          }));
        return r;
      }, Object.create(Object.prototype));

      const details = this.huecos.reduce((r, c) => {
        r[c.custom_key] ??= { details: Object.fromEntries(Object.entries(c.details).filter(([key, value]) => (c.details?.detailsCambios || []).includes(key))), dist: c.dist };
        this.$set(c.details,'detailsCambios',[])
        return r;
      }, Object.create(Object.prototype));
      console.log("Plan: ", result);
      console.log("Detalles: ", details);
      try {
        this.saving = true;
        await Promise.all([
          axios({
            method: "post",
            url: `/planificacion/${this.date}?timestamp=${new Date().getTime()}`,
            data: result,
          }),
          axios({
            method: "post",
            url: `/planificacion/${this.date}/details?timestamp=${new Date().getTime()}`,
            data: details,
          }),
          axios({
            method: "post",
            url: `/planificacion/${this.date}/confirmados?timestamp=${new Date().getTime()}`,
            data: this.equipo,
          }),
        ]);
        this.saving = false;

        this.$root.$emit("snack", "Se han guardado los cambios correctamente");
      } catch (err) {
        this.saving = false;
        // clearInterval(this.autosave)
        console.error(err);
        this.$root.$emit("snack", "No se han podido guardar los cambios");
      }
    },
    hasEvents() {
      return this.huecos.length > 0;
    },
    isNotPb() {
      return this.huecos.filter((ev) => ["Boda","Comunión","Evento","Corporativo"].includes(ev.tipo).length > 0);
    },
    isCat() {
      return (this.huecos.filter((ev) => ev.nombreEspacio == "CATERING" ).length > 0);
    },
    calc() {
      return this.huecos.filter((ev) => (ev.tipo == "Boda" || (((ev.nAdultos || 0) + (ev.nNinos || 0)) > 49)).length > 0);
    },
    async handleBeforeUnload(event) {
      event.returnValue = "Todos los cambios no guardados desaparecerán. ¿Seguro que quieres salir?";
      return event.returnValue;
    },
    removeBeforeUnloadListener() {
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }
  },
  mounted() {
    this.getHuecos();
    this.getCategorias();
    this.getEquipo();
    this.getEstadosUnidades();
    window.addEventListener('beforeunload', this.handleBeforeUnload);
		// Autosave
		// const self = this
		// this.autosave = setInterval( async function () {
		// 	await self.savePlan(true);
		// }, 1000 * 60 * 3 )

  },
  async beforeRouteLeave(to, from, next) {
    const confirmation = await this.$refs.confirm.open(
      "Guardar los cambios",
      "¿Quieres guardar los cambios antes de irte?",
      { color: "primary" }
    )
    if (confirmation) {
      await this.savePlan(true);
    }
    this.removeBeforeUnloadListener();
    next();
  },
	async beforeDestroy() {
    const confirmation = await this.$refs.confirm.open(
      "Guardar los cambios",
      "¿Quieres guardar los cambios antes de irte?",
      { color: "primary" }
    )
    if (confirmation) {
      await this.savePlan(true);
    }
    this.removeBeforeUnloadListener();
	},
  destroyed() {
    this.removeBeforeUnloadListener();
  },
};
</script>

<style>
</style>